import React from 'react';
import '../../styles/filterhead.css';

export default class FilterInfo extends React.Component
{
    render() {

        let persons = this.props.persons.length > 0 ? this.props.persons : null;
        let time = this.props.time.length > 0 ? this.props.time : null;
        let date = this.props.date.length > 0 ? this.props.date : null;

        return (
            <div className="filter_info_view">

              <h3 className="filter_header" data-toggle="collapse" data-target="#search_form">Filter</h3>


              <p className="filter_date">Datum: {date}</p>

            <p className="filter_time">Tid: {time}</p>

              <p className="filter_persons">Antal personer: {persons}</p>



            </div>
        )
    }
}