import React from 'react';

export default class OptionsPicker extends React.Component
{
    constructor() {
        super();

        this.renderOption = this.renderOption.bind(this);
    }

    renderOption(option) {
        return <button className="unchecked">{option.name}</button>;
    }

    render()
    {
        return (
            <div className="form-sub-container">
              <h3 className="personspicker_header">Utrustning:</h3>
                {this.props.techs.map(this.renderOption)}
            </div>
        )
    }
}