import React from 'react';

export default class Room extends React.Component
{
	constructor() {
		super ();

		this.renderTech = this.renderTech.bind(this);
	}

	renderTech(tech) {
		if (tech.technologyAvailability === 1) {
			return;
		}
		
		return <p className="availible checked">{tech.name}</p>
	}

	render() {

		const roomData = this.props.roomData;
		const roomImage = this.props.roomImage;

		return (
			<div className="room">

				<div className="room_image">{ roomImage }</div>

					<div className="roomServices">

						<h2 className="room_name">{roomData.name}</h2>

						<h2 className="roomServices--name">Sittnings-alternativ</h2>
						{this.props.roomData.tech.map(this.renderTech)}

						<h2 className="roomServices--name">Utrustning</h2>
						{this.props.roomData.tech.map(this.renderTech)}

						<h2 className="roomServices--name">Mat & Dryck</h2>
						{this.props.roomData.tech.map(this.renderTech)}

						<h2 className="roomServices--name">Tillval</h2>
						{this.props.roomData.tech.map(this.renderTech)}

					</div>

						<div className="room_options_info__persons">

							<h3 className="max_persons">Max antal: {roomData.maxSeats}</h3>

						</div>

						<div className="room_options_info__time">

							<input
								className="timepicker"
								type="range"
								min={0}
								max={2}
								value={this.props.time}
								onChange={(e) => this.props.updateTime(e.target.value)}
							/>

						</div>

						<div className="roomAlternatives">
							<a href="#" className="tag">FORTSÄTT</a>
						</div>

			</div>
		)
	}
}
