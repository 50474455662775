import React from 'react';

import FilterInfo from '../FilterInfo';
import ShowSearchForm from '../ShowSearchForm';

export default class VenueFilters extends React.Component {

	componentDidMount(){
		console.log($(".venue_filters").sticky());
		$(".venue_filters").sticky();
	}

  render () {
    return (
      <div className="venue_filters">
				<FilterInfo
					persons={this.props.persons}
					time={this.props.time}
					date={this.props.date}
				/>
				<ShowSearchForm
					persons={this.props.persons}
					updatePersons={this.props.updatePersons}
					time={this.props.time}
					updateTime={this.props.updateTime}
					date={this.props.date}
					updateDate={this.props.updateDate}
					techs={this.props.techs}
					updateOption={this.props.updateOption}
				/>
				<div className="row">
					<div className="ok">
					<p className="form_notice">Ange antal personer och datum för din bokning</p>
					</div>
				</div>
      </div>
    );
  }
}
