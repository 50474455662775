import React from 'react';
import '../../styles/room.css';

import Room from '../Room';

export default class VenueRooms extends React.Component {

	constructor() {
		super();

		this.renderRoom = this.renderRoom.bind(this);
	}

	componentWillUpdate() {
		
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.persons !== this.props.persons) {
			this.render();
		}
	}

	renderRoom(room) {
		const roomImage = room.imgUrl ? <img src={room.imgUrl} alt={room.name} className="roomImage"/> : null;

		if (room.maxSeats < this.props.persons) {
			return;
		}

		return (
			<Room key={room.id} roomData={room} roomImage={roomImage} time={this.props.time} updateTime={this.props.updateTime} />
		)
	}

	render() {
		return (
			<div className="roomContainer">
				{this.props.rooms.map(this.renderRoom)}
			</div>
		)
	}
}
