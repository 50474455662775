import React from 'react';

import MainSlider from '../MainSlider';
import DescriptionData from '../DescriptionData';
import VenueMap from '../VenueMap';

export default class VenueHeader extends React.Component {
    render() {
        return (
            <div className="venue-wrap row">
                <div className="infoWrapper col-md-4 col-sm-12 col-xs-12">
                    <VenueMap key="desktopMap" lat={this.props.venueData.visitingAddress.lat} long={this.props.venueData.visitingAddress.long} />
                    <DescriptionData description={this.props.venueData.plantFacts} />
                </div>
                <div className="sliderWrapper col-md-8 col-sm-12 col-xs-12">
                    <MainSlider />
                </div>
            </div>
        )
    }
}
