import React from 'react'

import PersonsPicker from '../PersonsPicker';
import ReactDatePicker from '../ReactDatePicker';
import TimePicker from '../TimePicker';
import OptionsPicker from '../OptionsPicker';

export default class ShowSearchForm extends React.Component {

	componentDidMount(){
		$(".venue_filters .ok a").click(function(e) {
      e.preventDefault(),
      $(".content").removeClass("darker_nr"),
      $(".venue_filters .row .person, .venue_filters .row .date, .venue_filters .row .time").removeClass("col-md-6"),
      $(".venue_filters .row .person, .venue_filters .row .date, .venue_filters .row .time").addClass("col-md-5"),
      $(".venue_filters").removeClass("fill_it")
    })
	}

  render () {
    return (
			<div id="search_form" className="row collapse collapsing">
				<form id="second_search_form">
					<ReactDatePicker date={this.props.date} updateDate={this.props.updateDate} />
          <div className="form-sub-container">
            <TimePicker time={this.props.time} updateTime={this.props.updateTime} />
            <PersonsPicker persons={this.props.persons} updatePersons={this.props.updatePersons} />
          </div>
          <OptionsPicker techs={this.props.techs} updateOption={this.props.updateOption} />
	      </form>
			</div>
    );
  }
}
