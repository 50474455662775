import React from 'react';

import createSearch from '../../helpers/createSearch.js';

export default class PersonsPicker extends React.Component {

	componentDidMount(){
		// $("[name=persons]").change(function() {
		// 	createSearch()
		// 	if($(this).val()) $(this).removeClass('error');
		// 	if($("[name=date]").val() && $("[name=persons]").val()) $(".venue .venue_filters .ok").hide();
		// })
	}

  render () {
    return (
      <div className="col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0 col-xs-12 col-xs-offset-0 person">
				<h3 className="personspicker_header">Antal personer:</h3>
				<div className="styled_select">
					<input
	            tabIndex = "1"
	            type = "range"
							min = {0}
							max = {100}
	            value = { this.props.persons }
	            autoComplete = "off"
	            name = "persons"
	            placeholder = { lang.searchPersons }
							onChange={(e) => this.props.updatePersons(e.target.value)}
						/>
				</div>
      </div>
    );
  }
}
