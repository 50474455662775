import React from 'react';

export default class TimePicker extends React.Component
{
    render() {
        return (
          <div className="col-sm-12 col-sm-offset-0 col-md-12 col-md-offset-0 col-xs-12 col-xs-offset-0 time">

            <h3 className="timepicker_header">Tid:</h3>
            <input
                className="timepicker"
                type="range"
                min={0}
                max={2}
                value={this.props.time}
                onChange={(e) => this.props.updateTime(e.target.value)}
            />

      </div>
        )
    }
}