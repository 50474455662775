import React from 'react';

export default class MainSlider extends React.Component {

	state = {
		slides: venueData.plantImages
	}

  componentDidMount(){
    $(".venue_slider .bxslider").bxSlider({
      slideMargin: 0,
      speed: 500,
      pagerCustom: '#bx-pager',
      controls: !0,
      randomStart: !1,
      preloadImages: "all",
      adaptiveHeight: false,
      responsive: !0,
      touchEnabled: true,
      nextText: "",
      prevText: "",
      onSliderLoad: function(e) {}
    });
  }

  renderItem(item){
    return (
      <li key={`slide_${item.id}`}><img src={item.url} /></li>
    );
  }

  renderPager(item){
    const index = arguments[1];
    return (
      <a data-slide-index={index} href="">
        <img src={item.url} />
      </a>
    )
  }

  render () {

    if(this.state.slides.length == 0){
      return (
        <div className="clearfix" style={{height: '180px'}}></div>
      );
    }

    return (
      <div className="venue_slider">
        <ul className="bxslider">
          {this.state.slides.map(this.renderItem)}
        </ul>
        <ul id="bx-pager">
          {this.state.slides.map(this.renderPager)}
        </ul>
      </div>
    );
  }
}
