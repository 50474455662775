import React from 'react'
import createSearch from '../../helpers/createSearch.js';

import VenueHeader from '../VenueHeader';
import VenueFilters from '../VenueFilters';
import OptionsNearby from '../OptionsNearby';
import VenueRooms from '../VenueRooms';
import VenueMap from '../VenueMap';

export default class Venue extends React.Component {

	constructor(){
		super();

		this.state = {
			venueData: venueData,
			venueRooms: venueRooms,
			venueAddress: venueAddress,
			persons: {},
			time: {},
			date: {}
		}

		this.updatePersons = this.updatePersons.bind(this);
		this.updateTime = this.updateTime.bind(this);
		this.updateDate = this.updateDate.bind(this);
		this.updateOption = this.updateOption.bind(this);
	}

	componentWillUpdate() {

	}

	componentDidUpdate () {

	}

	updatePersons (value) {
		const persons = this.state.persons;
		this.setState({ persons: value });
	}

	updateTime (value) {
		const time = this.state.time;
		this.setState({ time: value })
	}

	updateDate (newDate) {
		const date = this.state.date;
		this.setState({ date: newDate })
	}

	updateOption (option, key) {
		const options = {...this.state.technologies};
		options[key] = option;
		this.setState({technologies: options});
	}

	render () {
		return (
			<div className="venue">
				<div className="container">
					<div className="row">
						<VenueHeader venueData={this.state.venueData} />
						<VenueFilters
							persons={this.state.persons}
							time={this.state.time}
							updatePersons={this.updatePersons}
							updateTime={this.updateTime}
							date={this.state.date}
							updateDate={this.updateDate}
							techs={this.state.venueData.technologies}
							updateOption={this.updateOption}
						/>
						<VenueRooms rooms={this.state.venueRooms} persons={this.state.persons} time={this.state.time} updateTime={this.updateTime} />
						<OptionsNearby venue={ 2 } />
					</div>
				</div>
			</div>
    	);
  	}
}
